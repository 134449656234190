(function () {
    'use strict';

    (function () {
      //remove no-js class
      document.documentElement.className = document.documentElement.className.replace("no-js", "js"); 

      //Open links with rel='external' in new window/tab
    const smoothScroll = ()=>{  
        const links = document.querySelectorAll('a[href^="#"]');
        // console.log(links);
        for (var i = 0; i < links.length; i++) {
            //On click open in new window/tab
            links[i].addEventListener('click', function(e) {
                e.preventDefault();
                const id = this.getAttribute('href');
                console.log(id);
                // smooth scroll to element ID that matches id
                document.querySelector(id).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        }
    };

      
      window.addEventListener("load", function () {
        smoothScroll();
      });

    })();

}());
